
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');
:root{
  --bgColor : #1e1925;
}

*{
  margin: 0;
  /* background-color: var(--bgColor); */
  font-family: 'Poppins', sans-serif;

}

.whatsapp{
  text-decoration: none;
  color : black;
}
/* .home_page{
  background-image: url('../assets/1.jpg');
  background-size: cover;
} */
.home_container{
  padding: 0px 0px 0px 0px;
  box-sizing: border-box;
  width: 100%;
  height: 90vh;
  display: flex;
  position: relative;
  background-color: white;
  
}

.home_book_container{
  flex:1;
  padding:60px 0px 0px 150px ;

}

.home_book_container h1{
   font-size: 3.4rem;
   line-height: 4.5rem;

}

.second_header{
  color: gray;
  letter-spacing: 1px;
}

.home_search_container{
  padding: 10px 20px;
  border-radius: 20px;
  position: absolute;
  display: flex;
  align-items: center;
  gap: 20px;
  background-color: white; 
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}





.home_image{
  flex: 1;
  padding: 50px 100px 0px 0px;
}

.home_image img{
  width: 85%;
}

.heroSection{
  position: absolute;
  top: 0px;
  height: 100vh;
 
  max-height: 100vh;

  z-index: 100 ;
}

.background_img{
  position: fixed;
  height: 100vh;
  box-sizing: border-box;
  width: 100%;
  z-index:-10;

}



header{
  display: flex;
  justify-content: center;
  padding: 10px 0px;
}
main header .logo{
    width: 200px;
    object-fit: cover;
}

/* search container */

.change_members{
  position:absolute;
  
}

.search-container {
  position: absolute;
  top: 1;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  display: flex;
  justify-content: space-evenly;
  gap: 20px;
  align-items: center;
  height: 45px;
  width: fit-content;
  background-color: #f1f1f1;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

.change_div {
  position: relative;
    cursor: pointer;
    padding: 10px;
    background-color: white;
    border-radius: 5px;

}

.change_members{
  width: 100%;
  box-sizing: border-box;
  bottom: 200%;
  background-color: #f1f1f1;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
 
}
.inc_dec{
  display: flex;
  width: 100%;
  box-sizing: border-box;
  align-items: center;
  
  justify-content: space-between;
}
.inc_dec div{
 display: flex;
 justify-content: space-evenly;
 width: 50%;

}
.change_members div{
  padding: 5px 20px;
  
}
.inc_dec span{
   padding: 10px;
}


.change_members button{
   background-color: white;
   border: none;
   padding: 10px 15px;
   background-color: white;
   cursor: pointer;
   width: fit-content;
   border-radius: 4px;
   
}



.count{
  padding: 10px 15px;
}
input[type="date"] {
  padding: 10px;
  border: 1px solid gray;
  border-radius: 5px;
  font-size: 16px;
}



.book_btn {
  background-color:var(--bgColor);
  border: none;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: 250ms;
}

.book_btn:hover {
  opacity: 0.8;
  transition: 200ms;
}


hr{
  border: 1px solid black;
  height: 50px;
}




/* our rooms */


.our_room_container{
   background-color: #f1f1f1;
   text-align: center;
   padding: 40px;
}

.our_room_container h2{
  font-size: 40px;
  padding: 5px;
  font-weight: lighter;
}

.room_cards{
  padding: 70px 0px;
  display: flex;
  gap: 30px;
  justify-content: center;
}
@media (max-width: 900px) {
  .room_cards {
    flex-direction: column;
    align-items: center;
  }
}

.room_card{
  cursor: pointer;
  width: 350px;
  min-height: 23rem;
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
  border-radius: 2px;
  overflow: hidden;
  background-color: white;
  
}

.room_card_img{
  width: auto;
  height: 65%;

  overflow: hidden;
  
}


.room_card_img img{
  width: 100%;
  height: 100%;
  transition: 300ms;
  
}
.room_card_img img:hover{
  transform: scale(1.1);
  
}

.room_title{
  font-size: 20px;
  letter-spacing: 1px;
  font-weight: lighter;
  padding: 5px;
  text-transform: uppercase;
}

.room_card span{
  color: gray;
  letter-spacing: 1px;
}









/* second page */

#second_page{
  /* background-color: var(--bgColor); */
  background-color: #1E1925;
/* background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='400' height='400' viewBox='0 0 800 800'%3E%3Cg fill='none' stroke='%23404' stroke-width='1'%3E%3Cpath d='M769 229L1037 260.9M927 880L731 737 520 660 309 538 40 599 295 764 126.5 879.5 40 599-197 493 102 382-31 229 126.5 79.5-69-63'/%3E%3Cpath d='M-31 229L237 261 390 382 603 493 308.5 537.5 101.5 381.5M370 905L295 764'/%3E%3Cpath d='M520 660L578 842 731 737 840 599 603 493 520 660 295 764 309 538 390 382 539 269 769 229 577.5 41.5 370 105 295 -36 126.5 79.5 237 261 102 382 40 599 -69 737 127 880'/%3E%3Cpath d='M520-140L578.5 42.5 731-63M603 493L539 269 237 261 370 105M902 382L539 269M390 382L102 382'/%3E%3Cpath d='M-222 42L126.5 79.5 370 105 539 269 577.5 41.5 927 80 769 229 902 382 603 493 731 737M295-36L577.5 41.5M578 842L295 764M40-201L127 80M102 382L-261 269'/%3E%3C/g%3E%3Cg fill='%23505'%3E%3Ccircle cx='769' cy='229' r='5'/%3E%3Ccircle cx='539' cy='269' r='5'/%3E%3Ccircle cx='603' cy='493' r='5'/%3E%3Ccircle cx='731' cy='737' r='5'/%3E%3Ccircle cx='520' cy='660' r='5'/%3E%3Ccircle cx='309' cy='538' r='5'/%3E%3Ccircle cx='295' cy='764' r='5'/%3E%3Ccircle cx='40' cy='599' r='5'/%3E%3Ccircle cx='102' cy='382' r='5'/%3E%3Ccircle cx='127' cy='80' r='5'/%3E%3Ccircle cx='370' cy='105' r='5'/%3E%3Ccircle cx='578' cy='42' r='5'/%3E%3Ccircle cx='237' cy='261' r='5'/%3E%3Ccircle cx='390' cy='382' r='5'/%3E%3C/g%3E%3C/svg%3E"); */
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));

  gap: 30px;
  padding: 100px 10rem;
}



.image_section{
  width: 50%;

  overflow: hidden;
}

.one{
  grid-column: 1;
  grid-row: 1 / span 2;
  width: 100%;
}
.header_second{
  color: #e7d2d2;
  font-size: 3rem;
  font-family: 'Aboreto', cursive;
  padding: 40px 30px;
  grid-column: 2/span 3;

}
.two{
  width: 100%;
  grid-column: 2 ;
  grid-row: 2 ;
}
.second_description{
  grid-column: 3 /span 2;
  grid-row: 2 ;
  color: #c49c9c;
  padding: 30px 50px;
  letter-spacing: 1px;
  font-family: 'Poppins', sans-serif;
}

.image_section img{
  width: 100%;
  height: auto;
  display: block;
  margin-top: 30px;
  box-sizing: border-box;
}
.image_section img:hover{
  /* transform: scale(1.3); */
}

.image_section video{
  width: 100%;
}


/* third page */

.services_container{
  padding-bottom: 40px;

}
.services_container h2{
  font-size: 40px;
  padding: 5px;
  font-weight: lighter;
  text-align: center;
}

.service_div{
  padding: 70px 100px;
  display: flex; 
  flex-direction: row;
  gap: 50px;
  flex-wrap: wrap;
  justify-content: center;
}
.service{
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 180px;
  height: 150px;
  padding: 20px;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
}
@media (max-width: 900px) {
  .service_div {
    flex-direction: column;
    align-items: center;
  }
}

.services_desc{
  background-color: #1E1925;
}


@media only screen and (max-width: 768px) {
  .awards-container div ,.categories_img {
    flex-basis: calc(50% - 10px);
  }

}

@media only screen and (max-width: 480px) {
  .awards-container div , .categories_img{
    flex-basis: calc(100% - 10px);

  }

}
/*bottom*/





.bottom_container{
   text-align: center;
   padding: 60px 0px;
   background: transparent;
   background-color: white;
   color: black;
   letter-spacing: 1px;
  
}
.bottom_container p{
  line-height: 30px;
}

.icons{
  display: flex;
  gap: 20px;
  width: fit-content;
  margin: auto;
  padding: 20px 0px;
}

.icons svg{
  cursor: pointer;
  transition: 300ms;
  color: black;
}


.icons svg:hover{
  transform: scale(1.2);
}





@media only screen and (max-width: 988px) {
  .home_book_container h1{
    font-size: 3.5rem;
    line-height: 4rem;
 
 }
 .home_book_container p{
  font-size: 14px;
 }

 .home_book_container{
 
  padding:100px 0px 0px 100px ;

}
.home_image{

  padding: 100px 50px 0px 0px;
}
}

@media only screen and (max-width : 768px) {
  .home_container{
    height: 100vh;
    color: white;
    
    background-image: url('../assets/gallery/3.jpg');
    background-color: rgba(0, 0, 0, 0.3);

    background-blend-mode: overlay;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: left;
  }
  .home_book_container{
    padding:80px 10px ;
    text-align: center;
  
  }
  .home_search_container{
    color: white;
    gap: 5px;
    position: relative;
    width: fit-content;
    margin: auto;
    background-color: transparent;
  }
  .home_search_container label{
    display: none;
  }
  .home_search_container input{
    display: none;
  }
  hr{
    display: none;
  }
  .home_image{
     display: none;
  }
  .second_header{
    color:white;
    letter-spacing: 1px;
  }
}

@media only screen and (max-width : 700px){
  .home_search_container{
    display: block;
  }
}

@media only screen and (max-width:480px){
  .home_container{
    background-position: center;
  }
  .home_image{
    display: none;  
  }
  .home_book_container h1{
    font-size: 3rem;
    line-height: 4rem;
 
  }
  .home_book_container{
 
    padding:50px 0px 0px 0px;
  
  }
}

