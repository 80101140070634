:root{
    --bgColor : #1e1925;
  }

.card-slider {
    display: flex;
     flex: 2;
    flex-direction: column;
    align-items: center;
    overflow-y: visible;
    position: relative;

  } 
  
  .card-container {
    display: flex;
    overflow-x: hidden;
    overflow-y: visible;
    height:23rem;
  }

  
  .card {
    text-align: center;
    letter-spacing: 1px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 5px;
    transition: transform 1s, flex 1s;
    opacity: 0.5;
    background-color: white;
    padding: 20px;
    width: 14rem;
    max-height: 16rem;
    min-height: 12rem;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }
  
  .card img {
    width: 100%;
    height: auto;

  }
  
  .card.center {
    max-height: 18rem;
    min-height: 15rem;
    transform: scale(1.2);
    z-index: 10;
    opacity: 1;
    
    transition: transform 0.5s, flex 0.5s;
  }
  

 
  .arrow-container {
    margin-top: 10px;
  }
  


  .reviewSlider{
    background-color: white;

  }

  .innerSlider{

    padding: 60px;
    position: relative;

    background-color: var(--bgColor);
    display: flex;
    gap: 20px;
  }

  .desc-review{
    color: white;
    flex:1;
    padding:50px 10px;
    letter-spacing: 1px;
  }
  .desc-review button{
    border: none;
    background-color: white;
    padding:10px 20px;
    border-radius: 4px;
  }
  .desc-review a{
    text-decoration: none;
  }

  .imageDiv{
    height: 50px;
    width: 50px;
    border-radius: 50%;
    overflow: hidden;
  }


  .btnLeft{
    border: none;
    background-color: transparent;
    color: white;
    font-size: 5rem;
    float: left;
    position: absolute;
    left: 30px;
    top: 30%;
    cursor: pointer;
  }

  .btnRight{
    border: none;
    background-color: transparent;
    color: white;
    font-size: 5rem;
    position: absolute;
    top: 30%;
    right: 30px;
   
    cursor: pointer;
  }

  @media only screen  and (max-width : 1000px) {
    .innerSlider{

      display: flex;
      flex-direction: column;

    }
  
  }


  @media only screen  and (max-width : 840px) {

    .card {

      width: 8rem;
      height: 10rem;
      overflow: hidden;
      font-size: 12px;
   
    }
  
  }

  @media only screen  and (max-width : 550px) {


    .card {

      width: 6rem;
      height: 8rem;
      overflow: hidden;
      font-size: 10px;
   
    }
  
  }

  @media only screen  and (max-width : 430px) {

   .card-slider{
    display: none;
   }
  
  }