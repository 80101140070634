/* #contact_us{
    min-height: 100vh;
}

form {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    max-width: 500px;
    height: fit-content;
    padding: 50px;
    margin:  auto;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  /* input[type="text"],
  input[type="email"],
  textarea {
    width: 100%;
    padding: 10px;
    border: 2px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    font-family: Arial, sans-serif;
    resize: none;
  }
   */
  /* button[type="submit"] {
    display: block;
    margin-top: 20px;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: #008080;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
  }
  
  button[type="submit"]:hover {
    background-color: #005353;
  }
  
  @media only screen and (max-width: 600px) {
    form {
      max-width: 90%;
    }
  }
   */ 







  .form-container {
    background: #fff;
    width: 800px;
    height:fit-content;
    padding: 20px;
    border-radius: 24px;
    box-shadow: 16px 16px 8px rgba(0, 0, 0, .1);
    display: flex;
    justify-content: center;
    align-items: center;


  }
  
  .picture-container {
  /*   border: 1px solid blue; */
  flex: 1;
    width: 90%;
    height: 100%;
    align-self: flex-start;
    display: flex;
    justify-content: center;
    align-items: center;

  }

  @media only screen and (max-width: 600px) {
    .picture-container{
      display: none;
    }
  }

  .picture-container img{
    width: 90%;

  }
  .contact-form-container {
    height: 90%;
    flex: 1;
  }
  
  .contact-form {
    height: 100%;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-content: space-around;
  
  }
  
  .form-header {
    font-size: 2em;
    font-weight: 700;
    margin-bottom: 10px;
    align-self: flex-start;
  }
  
  .email-input {
    border: none;
    outline: none;
    background: #eee;
    padding: 20px;
    border-radius: 6px;
    width: 100%;
    font-size: 100%;
    margin-bottom: 10px;
  }
  
  .message {
    border: none;
    outline: none;
    resize: none;
    background: #eee;
    padding: 20px;
    border-radius: 6px;
    width: 100%;
    font-size: 100%;
    margin-bottom: 10px;
  }
  
  .submit {
    border: none;
    outline: none;
    color: #fff;
    width: 100%;
    padding: 20px;
    background: var(--bgColor) ;
    font-size: 100%;
    font-weight: 600;
    border-radius: 6px;
    cursor: pointer;
    transition: transform 300ms 0s cubic-bezier(0, 0.23, 0.29, 2.45);
  }
