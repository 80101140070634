:root{
    --bgColor : #1e1925;
}
.rooms{
    padding: 60px 30px;
}


.rooms_image_container{
   box-sizing: border-box;
    width: 100%;
   padding: 20px 120px;
   display: grid;
   grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));
   object-fit: contain;
   height: 58vh;
   gap: 20px;
}


.rooms_image_container img{
    width: 100%;
    overflow: hidden;
    object-fit: cover;
    border-radius: 10px;
}
.r1{
    height: 100%;
    grid-column: 1 / span 2;
    grid-row: 1 /span 2;
}

.r2{
    grid-column: 3 ;
    grid-row: 1;
    height: 100%;
}
.r3{
    height: 100%;
    grid-column: 4;
    grid-row: 1;
}
.r4{
    grid-column: 3 ;
    grid-row: 2;
    height: 100%;
    object-fit: cover;
}
.r5{
    grid-column: 4;
    grid-row: 2;
    height: 100%;
    object-fit: cover;
}

.room_description_container{
    padding: 20px 7rem;
    display: flex;
    gap: 40px;

    justify-content: space-between;
}   


.basic_facillities{
    padding: 20px 0px;
    display: flex;
    gap: 20px;
    width: fit-content;
    flex-wrap: wrap;
}

.basic_facillities div{
    width: 180px;
    height: 180px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    border-radius: 10px;
    background-color: #eff0f2;
    align-items: center;
}
.basic_facillities p{
   text-align: center;
}


.amenities_container{

    display: flex;
    flex-wrap: wrap;
    gap: 30px;
   
}

.amenities{
    display: flex;
    gap: 5px;
    align-items: center;
}

/* .other-rooms{
    padding: 20px 0px;
    width: fit-content;
    display: flex;
    gap: 30px;
}
.other-rooms>div{
   cursor: pointer;
   padding: 10px 20px;
   border-radius: 4px;
   box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
} */

.price_container{
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: 20px;
    width: fit-content;
    height: fit-content;
    border-radius: 10px;
}

.book_button{
    padding: 10px 30px;
    border-radius: 40px;
    border: none;
    margin: 20px;
    color: white ;
    background-color: var(--bgColor);
    cursor:pointer;
}


@media only screen and (max-width : 1190px){
    .room_description_container{
        padding: 20px 20px;
        display: flex;
  
    }   
}
@media only screen and (max-width : 1100px){
    .basic_facillities div{
   
        width: 150px;
        height: 150px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;
        border-radius: 10px;
        background-color: #eff0f2;
        align-items: center;
    }
}



@media only screen and (max-width : 1000px){
    .r3{
        display: none;
    }
    .r5{
        display: none;
    }
}
@media only screen and (max-width : 760px){
    .r2{
        display: none;
    }
    .r4{
        display: none;
    }
    .basic_facillities{
        padding: 20px 0px;
        display: flex;
        gap: 10px;
        justify-content: center;
        flex-wrap: wrap;

    }
    .room_description_container{
       font-size: 12px;
        display: flex;
        gap: 10px;
        flex-direction: column;
       padding: 20px 0px ;

    }   
    
}

@media only screen and (max-width : 445px ){
 .amenities_container{

 }
    
}

@media only screen and (max-width : 520px){
    .rooms{
        padding: 30px 20px;
    }
    .rooms_image_container{
        padding: 10px 10px;

        
     }

  
    
}


/* offer */

