
:root{
    --bg : #1e1925;
}
::-webkit-scrollbar {
    width: 0;
}



.package1{
    background-color: white;
    margin: 0;
    padding:0px;
    min-height: 100vh;
    width: 100%;
    box-sizing: border-box;
    position: relative;
    color: var(--bg);
    overflow: hidden;
    letter-spacing: 1px;

}


.day1{
    width: fit-content;
    height: fit-content;
    overflow-y: scroll;
    padding: 80px 100px 100px;
    display: grid;

    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    grid-gap: 60px;
}
h3{
    font-size: 25px;
    
}

.day1_img{
    
    
    position: sticky;
    top: 0;
    height: 100%;
    width: 100%;
    overflow:hidden;
}

.day1_img img{
    position: sticky;
    top: 0;
    margin: 0;
    padding: 0;
    width: 100%;
    transition: 1000ms;
}
.day1_img img:hover{
    /* transform: scale(1.2); */
}

.day1_desc{
    height: 60vh;
    overscroll-behavior: smooth;
    overflow-x: visible;
}

li{
    line-height: 30px;
    font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

.package_book_btn{
    margin-top: 20px;
    font-size: 16px;
    float: right;
    cursor: pointer;
    padding: 10px 40px;
    background-color: #1e1925;
    color: #1e1925;
    color: white;
    border: none;
    border-radius: 4px;
}

@media only screen and (max-width : 1044px){
    .package1{

        padding:0px;
        min-height: fit-content;
        width: 100%;

    
    }
    .day1{

        padding: 80px 50px 100px;

    

    }

    .day1_desc{
        height: fit-content;

    }


    
}

@media only screen and (max-width : 700px){

    .day1{

        padding: 80px 20px 100px;

    

    }


    
}

@media only screen and (max-width : 445px){

    .day1{
     font-size: 12px;

     padding: 80px 10px 100px;

    }


    
}
