

.activites_div{
    position: relative;
    height: 100vh;
    color: white;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    letter-spacing: 1px;
    gap: 20px;
}

.activites_div h2{
    font-size: 40px;
    z-index: 10;
    font-weight: lighter;
}

.activities p{
    cursor: pointer;
}


.activities{
    display: flex;
    gap: 40px;
    font-size: 25px;
    z-index: 3;
}

.jeep_safari{
   
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -10;
    opacity: 0;
    background-color: rgba(0, 0, 0, 0.4);
    background-image: url('../assets/safari.JPG');
    
    background-size: cover;
    transition: 500ms;
   
}
.jeep_safari_p{

    z-index: 100;
}
.jeep_safari_p:hover ~ .jeep_safari{
   opacity: 1;
}


.jungle_walk{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -10;
    opacity: 0;
    background-color: rgba(0, 0, 0, 0.4);
    background-image: url('../assets/jungle-walk.jpeg');
    background-size: cover;
    transition: 500ms;
}


.jungle_walk_p:hover ~ .jungle_walk{
    opacity: 1;
}

.photograph{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -10;
    opacity: 0;
    background-color: rgba(0, 0, 0, 0.4);
    background-image: url('../assets/photograph.jpeg');
    background-size: cover;
    transition: 500ms;

}

.photograph_p:hover ~ .photograph{
    opacity: 1;
}


@media only screen and (max-width: 768px){

  .activites_div{
   
    gap: 10px;
}
.activites_div h2{
  font-size: 30px;

}

.activities{
  
  gap: 20px;
  font-size: 20px;
}

}

@media only screen and (max-width : 480px){
  .activities{
    justify-content: center;
    gap: 10px;

  }
  .activities p {
    display: inline;
  }
}

/* offers */


.offers{
    padding: 70px 0px;
    display: flex;
    gap: 30px;
    justify-content: center;
    
  
  }
  
  
  
  .pickup_card{
    position: relative;
    height: 450px;
    width: 400px;
    background-image: url('../assets/pickup.jpeg');
    background-size: cover;
    
  }
  
  
  .offers h3{
    position: absolute;
    text-align: center;
    bottom: 30px;
    color: white;
    z-index: 10;
    width: 100%;
    letter-spacing: 1px;
    font-size: 30px;
  }
  
  .breakfast_card{
    position: relative;
    height: 450px;
    width: 400px;
    background-image: url('../assets/breakfast.jpeg');
    background-size: cover;
  }
  
  .drink_card{
    position: relative;
    height: 450px;
    width: 400px;
    background-image: url('../assets/drinks.jpg');
    background-size: cover;
  }
  
  .drink_card:hover .bg_black{
    display: block;
    display: flex;
      flex-direction: column;
    justify-content: flex-end;
  }
  .drink_card:hover h3{
    display: none;
  }
  
  @media (max-width: 900px) {
    .offers {
      flex-direction: column;
      align-items: center;
    }
  }
  
  .bg_black{
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.3);
      z-index: 2;
      color: white;
      backdrop-filter: blur(0.5px);
      transition: 400ms; 
      
  }
  
  h4{
    font-size: 30px;
  }
  .breakfast_card:hover .bg_black{
    display: block;
    display: flex;
      flex-direction: column;
    justify-content: flex-end;
    transition: 400ms;
    
  }
  
  .breakfast_card:hover h3{
    display: none;
  }
  
  .pickup_card:hover h3{
    display: none;
  }
  
  
  .pickup_card:hover .bg_black{
    display: block;
    display: flex;
      flex-direction: column;
    justify-content: flex-end;
  }
  




  /* package */

  .package_container{
    background-color: #f1f1f1;
    padding: 10px 10vw;
  }


  .package_container h2{
    font-size: 40px;
    text-align: center;
    font-weight: lighter;
  }

  .package_details{
    display: flex;
    gap: 40px;
    padding: 60px 20px;
    letter-spacing: 1px;

  }

  .package_img{
    flex:1;
  }

  .package_img img{
    height: auto;
    width: 100%;
  }

  .package_info{
    flex:1;
    padding: 0px 0px 0px 20px;
  }

  .package_info p{
    line-height: 30px;
  }

  .learn_more{
    color: blueviolet;
    cursor: pointer;
    float: right;
  }

  @media only screen and (max-width:768px){
    .package_details{
      flex-direction:column;
      padding: 60px 0px;
      gap: 20px;
    }
    .middle{
      flex-direction:column-reverse;
    }
    .package_container{
    
      padding: 10px 30px;
    }
  }



/*reviews */


.reviews{
  background-color:white;
  padding: 50px 50px;
  letter-spacing: 1px;

}

.reviewContainer{
  display: flex;
  justify-content: space-evenly;
}
.reviews h3{
  text-align: center;
  font-size:4vw;
  color: black;
}
.reviewContainer img{
  width: 20%;
  height: 20%;
}


.rating{
  font-size: 8vw;
}


.review-book-container{
  text-align: center;
  padding: 0px 0px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.review-book-container h2{
  font-size: 40px;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.review-book-container p{
  font-size: 20px;
}



@media only screen and (max-width : 768px){
  .reviews{
    background-color:white;
    padding: 30px 30px;
    letter-spacing: 1px;
  
  }
  .review-book-container h2{
    font-size: 25px;
  }
  .review-book-container p{
    font-size: 16px;
  }
}


/* google map */

.google_map{
  display: flex;
  padding: 90px 90px;
  background-color: #f1f1f1;
  gap: 60px;
}
@media only screen and (max-width:768px){
  .google_map{
    flex-direction: column;
    
  }

}

@media only screen and (max-width:480px){
  .google_map{
    padding: 40px;
  }
  .second_p{
    display: none;
  }
}



/* overview */


.overview_div{
  background-color: #f1f1f1;
  padding: 20px;
  margin-top: 20px;
}

.overview_header{
  font-size: 40px;
  font-weight: lighter;
  padding: 5px;
  font-weight: lighter;
  text-align: center;
}

.overview_p{
  padding:  40px 50px;
  letter-spacing: 1px;
  line-height: 30px;
  width: 40%;
}
.overview_body{
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
@media only screen and (max-width : 900px) {

  .overview_p{
    padding: 20px 0px;
    width: 90%;

  }
  .overview_body{
   flex-direction: column-reverse;
   align-items: center;

  }
  .overview_div{
    background-color: #f1f1f1;
    padding: 40px 20px;
  }

}


@media only screen and (max-width : 700px) {
  .overview_div{
    background-color: #f1f1f1;
    padding: 40px 0px;
  }
  .overview_p{
    padding: 20px 0px;
  }
  .overview_div{
    background-color: #f1f1f1;
    padding: 0px;
  }

}
@media only screen and (max-width : 500px) {
  .overview_body video{
    width: 350px;
    height: 250px;
  }

}
