

.activitiesContainer{
    padding: 40px;
}

.activitiesContainer h2{
    font-size: 40px;
    padding: 5px;
    font-weight: lighter;
    text-align: center;
  }

  .activities_cards{
    padding: 70px 0px;
    display: flex;
    gap: 40px;
    justify-content: center;
    flex-wrap: wrap;
  }
  
  .activity_card{
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    cursor: pointer;
    width: 350px;
    min-height: 23rem;
    height: 400px;
    border-radius: 12px;
    overflow: hidden;
    background-color: white;
  }

  @media (max-width: 900px) {
    .activities_cards {
      flex-direction: column;
      align-items: center;
    }
  }

  .actvity_card_image{
    width: auto;
    height: auto;
    object-fit: contain;
    height: 55%;
    overflow: hidden;
  }
  .activity_card div img{
    width: 100%;
    height: 100%;
    transition: 300ms;
    object-fit: cover;
    
  }

  .read_more_btn{
    width: 100%;
    background-color: var(--bgColor);
    cursor: pointer;
    border: none;
    padding: 10px;
    color: white;
    border-radius: 4px;
  }

  .activity_title{
    font-size: 22px;
    text-align: center;
  }


/* guide css */

.guide{
  background-color: #f1f1f1;
  padding-bottom: 40px;

}

.guide h2{
  text-align: center ;
  font-size: 40px;
  padding: 5px;
  font-weight: lighter;
}

.guide_desc1{
  display: flex;
  
  gap: 100px;
  padding: 40px 120px;
}
.sombote{
  display: flex;
  align-items: center;
  flex-direction: column;
  
}
.sombote div{
  background: lightblue;
  border-radius: 50%;
  width: 180px;
  height: 180px;
  overflow: hidden;
  box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 0px 3px;
  object-fit: contain;
}
.sombote div img{
  object-fit: cover;
  height: 100%;
  width: 100%;
}

@media (max-width: 900px) {
  .guide_desc1 {
    flex-direction: column;
    align-items: center;
    padding: 40px 0px;
    gap: 20px;
  }

}
/* 
.guide_div{
  padding: 40px;
  display: flex;

  gap: 40px;
  justify-content: center;


}

.guide_card{
  border-radius: 8px;
  padding: 20px;
  width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}

.guide_card div{
  background: lightblue;
  border-radius: 50%;
  width: 150px;
  height: 150px;
  overflow: hidden;
  box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 0px 3px;
}
.guide_card img{
  height: 100%;
  width: 100%;
} */