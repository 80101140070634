:root{
    --bgColor : #1e1925;
  }

#gallery{
  padding: 40px;
   text-align: center;
    height: fit-content ;
}
#gallery h2{
  text-align: center;
  font-size: 35px;
  margin-bottom: 10px;
}

.container{
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
    justify-content: center;
    padding: 40px 80px;
}


.container div{

  width: 300px;
  height: 300px;
  transition: 200ms;
  cursor: pointer;
  border-radius: 4px;
  overflow: hidden;
  
}
.container div:hover{

   scale: 1.04;
  
}
.container div img{
    width: 100%;
    height: 100%;
    display: block;
    margin-bottom: 20px;
    box-sizing: border-box;
    object-fit: cover;
}

/* .container img:hover{
    transform: scale(1.2);
} */

@media (max-width: 800px) {
    .container {
        column-count: 3;
    }
  }
  
  /* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
  @media (max-width: 600px) {
    .container {
        column-count: 2;
    }
  }



  /* gallery section in home page */


  .gallerySection{
    background-color: white;
    text-align: center;
    padding:60px;
    letter-spacing: 1px;
  }

  .gallerySection .headerSection{
    margin: auto;
   width: 50vw;
   padding: 40px 0px;
  }

  .headerSection h1{
    font-size: 50px;
  }

  .headerSection p {
    color: gray;
    font-weight: bold;
    font-size: 18px;
  }
  .gallerySection .imageSection{
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    justify-content: center;
    padding: 40px;
  }

  .imageSection img {
    width: 23rem;
    height: 16rem;
    border-radius: 10px;
    object-fit: cover;
    object-position: center;
    transition: 200ms;
    cursor: pointer;
  }

  .imageSection img:hover{
    transform: scale(1.04);
  }

@media only screen and (max-width : 800px) {
.container{
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
  justify-content: center;
  padding: 40px 20px;
}
.gallerySection{
  padding: 0px 0px;
}
.gallerySection .headerSection{
  margin: auto;
 width: 80vw;
 padding: 40px 0px;
}

.headerSection p {
  color: gray;
  font-weight: bold;
  font-size: 14px;
}
.headerSection h1{
  font-size: 40px;
}
  }