@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');


.phone_number{
    display: flex;
    align-items: center;
}

select{
    border: none;
    font-size: 16px;
    background-color: transparent;
    color:black;
}
select:focus {
    outline: none;
}

nav{
 
    z-index: 1000;
    display: flex;
    overflow-y: auto;
    padding: 5px 40px;
    align-items: center;
    justify-content: space-between;
    gap: 50px;
}

.contactDetails{
    width: fit-content;
    display: flex;
   justify-content: flex-start;
    gap: 50px;
    padding: 0px 20px;
    letter-spacing: 1px;
}
.bold{
    font-size: 19px;
    font-weight: bold;
    text-decoration: none;
}
.tag{
    width: 20px;
}

.navigation {
    position: sticky;

    top: 0;
    box-sizing: border-box;
    height: 60px;
    z-index: 100;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 20px;
    padding: 0px 100px;
    transition: 1000ms;
    color: white;
    background-color: var(--bgColor);
  }

  .navInnerDiv{
    display: flex;
    gap: 60px;
  }

.active{
    position: static;
    padding: 0px 100px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    transition: 1000ms;
}

.navigate h2{
    line-height: 2rem;
    font-size: 25px;
}



nav .tag{
    width: 10rem;
}

nav .navigate{
    text-decoration: none;
    cursor: pointer;

}
Link{
    letter-spacing: 1px;
}

.links{
    display: inline-block;
  position: relative;
text-decoration: none;
  cursor: pointer;
  color: white;
  letter-spacing: 1px;
  
}
.links::after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: -4px;
    left: 0;
    background-color: white;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }
  
  .act::after{
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(1);
    height: 2px;
    bottom: -4px;
    left: 0;
    background-color: white;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }
  .links:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }

 
  

.navOptions{
    
    width: fit-content;
    gap: 40px;
    display: flex;
    
    justify-content: space-evenly;
}


.c{
    display: none;
}
.g{
    display: none;
}


/*package*/

.package{
     
     color: white;
     position: absolute;
     cursor: pointer;
     display: none;
     transition: height .4s ease;
     margin-top: 16px;
     transition: 300ms;
     border: 2px solid #1e1925;
}
.package>div{
    padding: 20px;
    transition: 300ms;
    background-color: #1e1925;
}
.package_link{
    
    color:white;
    text-decoration: none;
    transition: 300ms;
    letter-spacing: 1px;

}
.package:before {
    position: absolute;
    top: -20px;
    left: 0;
    width: 100%;
    height: 20px;
    content: '';
    display: block;
    z-index: 1;
  }
.package>div:hover{
    background-color:white;
    color: #1e1925;
}
.package>div:hover .package_link{
    color: #1e1925;
  
}
.package_link{
    color:white;
    cursor: pointer;
  
    
    /* padding-bottom: 45px; */
}

.in{
    background-color: #1e1925;
    
}
.all_packages:hover  .package{
   display: block;
    display: flex;
    flex-direction: column;
}

/* .package_link:hover + .package{
    background-color: #1e1925;
    visibility: visible;
}

.package:hover{
    visibility: visible;
} */

.rooms_link_container{
    color: white;
     position: absolute;
     cursor: pointer;
     display: none;
     transition: height .4s ease;
     margin-top: 16px;
     border: 2px solid #1e1925;
    
}
.rooms_link_container>div{
   padding: 20px;
   transition: 300ms;
   background-color: #1e1925;
}
.rooms_link{
    color:white;
    text-decoration: none;
    transition: 300ms;
    letter-spacing: 1px;

}
.rooms_link_container::before{
    position: absolute;
    top: -20px;
    left: 0;
    width: 100%;
    height: 20px;
    content: '';
    display: block;
    z-index: 1;
}
.rooms_link_container>div:hover{
   background-color: white;
   color: #1e1925;
 
}
.rooms_link_container>div:hover .rooms_link{
   color: #1e1925;
}
.rooms_link{

   color: white;
   cursor: pointer;
  
   
   /* padding-bottom: 45px; */

}


.all_rooms:hover  .rooms_link_container{
    display: block;
    display: flex;
    flex-direction: column;
    color: #1e1925;
}



.menu{
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    color: #1e1925;
    top: 100%;
    left: 0px;
    height: 100vh;
    width: 100vw;
    transition: 500ms;
    gap: 40px;
    font-size: 20px;
}

.menuLinks{
   font-size: 25px;
   text-decoration: none;
}
.menuBar{
    display : none
}
/* responsive */

@media only screen and (max-width: 988px) {
    .navOptions{
    
        width: fit-content;
        gap: 10px;
        display: flex;
        justify-content: space-evenly;
    }
    /* .links{
      display: none;
    } */

    .navigation{
       
        padding: 5px 40px;
       
    }

    .c{
        display: block;
    }
    .g{
        display: block;
    }
    
}

@media only screen and (max-width : 840px){
    .contactDetails{
        width: fit-content;
        display: flex;
        flex-direction: column;
        gap: 5px;
       justify-content: flex-start;
        padding: 0px 10px;
        letter-spacing: 1px;
    }
    /* .navigation{
        background:white;
        color: black;
        align-items: center;
    } */
    .active{
        
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        transition: 1000ms;
        background-color: white;
        color: black;
    }

    .active select{
        color: black;
    }
    .all_rooms{
        display: none;
    }
    .all_packages{
        display: none
    }
    .menuBar{
        display: block;
    }
    select{
        color: black;
    }
    .navInnerDiv{
        display: flex;
        gap: 60px;
      }
}

@media only screen and (max-width : 700px){
    nav{
        z-index: 1000;
        display: flex;
        overflow-y: auto;
        padding: 5px 20px;
        align-items: center;
        justify-content: flex-start;
        gap: 20px;
    }
    .contactDetails{
        width: fit-content;
        display: flex;
        flex-direction: column;
       justify-content: flex-start;
        padding: 0px 10px;
        letter-spacing: 1px;
        font-size: 12px;
    }
    .phone_number{
        display: flex;
        align-items: center;
        font-size: 12px;
    }
    .navigation{
        padding: 5px 20px;
        width: 100vw;
    }
    nav .tag{
        width: 8rem;
    }
    .navOptions{
    
      
        gap: 20px;
        
    }

    .phone{
        width: 10px;
    }
}

@media only screen and (max-width : 520px){
    nav{
        padding: 8px;
    }

    .contact_link{
        display: none;
    }
    .gallery_link{
        display: none;
    }
    .bold{
        font-size: 14px;
        font-weight: bold;
    }
}